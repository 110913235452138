// import { captureException } from '@sentry/gatsby';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import axios from 'axios';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import HubspotForm from 'react-hubspot-form';
import { FormStyles } from '../../styles/Components/Forms/FormStyles';
import { FormSpinner } from '../../utils/Spinner';
import { getHubspotUtk } from '../../utils/getHubspotUtk';
import setValuesFromParams from './utils/setValuesFromParams';

const DemoForm = props => {
  const demoFormUrl = `https://api.hsforms.com/submissions/v3/integration/submit/5424293/${hubspotFormId}`;
  const { data } = useStaticQuery(getData);
  const hubspotFormId =
    props.formId ||
    data?.nodes.filter(node => node?.internalName === 'Demo Form')[0].formId;

  const [shoptype, setShoptype] = useState([]);
  const [validated, setValidated] = useState(false);
  const [shoptypeError, setError] = useState(false);
  const [submitFlag, setSubmitFlag] = useState(false);
  const [submitMsg, setSubmitMsg] = useState('Submitting Data...');
  const [utmParameters, setParameters] = useState({});
  const [hutk, setHutk] = useState('');
  const [demo, setDemo] = useState({
    email: '',
    firstname: '',
    lastname: '',
    company: '',
    phone: '',
    shop_type: '',
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHutk(getHubspotUtk(document));
      setParameters({
        utm_campaign: window.sessionStorage.getItem('utm_campaign'),
        utm_source: window.sessionStorage.getItem('utm_source'),
        utm_medium: window.sessionStorage.getItem('utm_medium'),
        utm_term: window.sessionStorage.getItem('utm_term'),
        utm_content: window.sessionStorage.getItem('utm_content'),
        google_click_id: window.sessionStorage.getItem('gclid'),
      });
    }
  }, []);

  const handleSubmit = e => {
    const fields = [];
    try {
      e.preventDefault();
      e.stopPropagation();
      const form = e.currentTarget;
      if (form.checkValidity() === false || demo.shop_type === '') {
        if (demo.shop_type === '') {
          setError(true);
        }
        setValidated(true);
        return false;
      }
      setValidated(true);
      Object.keys(demo).forEach(key => {
        fields.push({ name: key, value: demo[key] });
      });

      Object.keys(utmParameters).forEach(key => {
        if (utmParameters[key] != null) {
          if (key === 'gclid') {
            fields.push({
              name: 'google_click_id',
              value: utmParameters[key],
            });
          } else {
            fields.push({ name: key, value: utmParameters[key] });
          }
        }
      });

      const pathName =
        typeof window !== 'undefined' ? window.location.href : '';
      const index = pathName.lastIndexOf('/');
      const trimmedPathName = pathName.substr(index);

      const pageTitle = document.querySelector('title').innerHTML;
      const trimmedPageTitle = pageTitle.split('|')[0];

      const postData = {
        submittedAt: Date.now(),
        fields,
        context: {
          pageUri: `https://www.shopmonkey.io${trimmedPathName}`,
          pageName: `${trimmedPageTitle}`,
          sfdcCampaignId: `${
            hubspotFormId === '453bf9b1-46a5-4398-ab2c-670066688ced'
              ? `7014x000000QP1sAAG`
              : undefined
          }`,
          ...(hutk ? { hutk } : undefined),
        },
        skipValidation: true,
      };
      axios
        .post(demoFormUrl, postData)
        .then(() => {
          navigate('/thankyou');
          setSubmitMsg('Success!');
        })
        .catch(err => {
          console.log(`${trimmedPageTitle} Form Error - ${err.response.data}`);
          setSubmitMsg('Sorry, try again later');
        });
      setSubmitFlag(true);
      return false;
    } catch (err) {
      console.log(`Demo Form Error - ${err.response.data.errors[0].errorType}`);
    }
  };

  const handleChange = e => {
    setDemo({
      ...demo,
      [e.target.name]: e.target.value,
    });
  };
  const handleReadyForm = () => {
    setTimeout(() => {
      let selectItems = document.querySelectorAll('#hub-form select option');
      if (!selectItems || selectItems.length === 0) {
        if (document.querySelector('#hub-form iframe')) {
          selectItems = document
            .querySelector('#hub-form iframe')
            .contentDocument.querySelectorAll('select option');
        }
      }
      const types = [];
      selectItems.forEach(_option => {
        if (_option.value !== '') {
          types.push(_option.value);
        }
      });
      setShoptype(types);
    }, 1000);
  };

  const handleBeforeSubmit = (form, data) => {
    const findValue = data.find(item => item.name === 'contact_me_via_fax');
    if (!!findValue.value) {
      navigate('/thankyou');
    }
  };

  return (
    <FormStyles className="hero-form">
      <h4 className="form-title" id="demoform">
        {props?.componentData?.heading || 'Get a Personalized Demo'}
      </h4>
      <div id="hub-form" className="d-none">
        <HubspotForm
          formId={hubspotFormId}
          loading={<FormSpinner />}
          onFormReady={setValuesFromParams}
          onReady={form => handleReadyForm(form)}
          onBeforeFormSubmit={handleBeforeSubmit}
          onSubmit={handleSubmit}
          portalId={5424293}
        />
      </div>
      {!submitFlag ? (
        <Form
          noValidate
          validated={validated}
          id="demo-form"
          onSubmit={e => handleSubmit(e)}
        >
          <Form.Row>
            <Col md={6}>
              <Form.Group name="firstname">
                <Form.Control
                  type="text"
                  name="firstname"
                  id="firstname"
                  value={demo.firstname}
                  required
                  onChange={e => handleChange(e)}
                />
                <Form.Label className="required material-label">
                  First Name
                </Form.Label>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group name="lastname">
                <Form.Control
                  type="text"
                  name="lastname"
                  id="lastname"
                  value={demo.lastname}
                  required
                  onChange={e => handleChange(e)}
                />
                <Form.Label className="required material-label">
                  Last Name
                </Form.Label>
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col md={6}>
              <Form.Group name="email">
                <Form.Control
                  type="email"
                  name="email"
                  value={demo.email}
                  required
                  onChange={e => handleChange(e)}
                  id="ddddd"
                />
                <Form.Label
                  className={`required material-label ${
                    demo.email !== '' ? 'filled' : ''
                  }`}
                >
                  Email address
                </Form.Label>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group name="phone">
                <Form.Control
                  type="text"
                  name="phone"
                  id="phone"
                  value={demo.phone}
                  required
                  onChange={e => handleChange(e)}
                />
                <Form.Label className="required material-label">
                  Phone Number
                </Form.Label>
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Group name="company">
            <Form.Control
              type="text"
              name="company"
              id="company"
              value={demo.company}
              required
              onChange={e => handleChange(e)}
            />
            <Form.Label className="required material-label">
              Company Name
            </Form.Label>
          </Form.Group>
          <div className="mb-4">
            <FormControl
              variant="outlined"
              className="w-100"
              error={shoptypeError}
            >
              <InputLabel id="shoptype-label">Shop type</InputLabel>
              <Select
                labelId="shoptype-label"
                id="shop_type"
                value={demo.shop_type}
                name="shop_type"
                label="Shop type"
                onChange={handleChange}
              >
                {shoptype.length > 0 &&
                  shoptype.map(type => (
                    <MenuItem style={{ fontSize: 18 }} value={type} key={type}>
                      {type}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <Button variant="success" type="submit" className="w-100">
            {props?.componentData?.callToAction || "Let's Do This"}
          </Button>
        </Form>
      ) : (
        <p>{submitMsg}</p>
      )}
    </FormStyles>
  );
};

const getData = graphql`
  {
    data: allDatoCmsWebsiteForm {
      nodes {
        formId
        internalName
      }
    }
  }
`;
export default DemoForm;
